<template>
	<section id="walks">
		<div class="py-12"></div>

		<v-container>
			<h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
				{{ $t('walkPosts') }}
			</h2>

			<v-responsive class="mx-auto mb-12" width="56">
				<v-divider class="mb-1"></v-divider>

				<v-divider></v-divider>
			</v-responsive>

			<v-responsive
				class="mx-auto title font-weight-light mb-8"
				max-width="720"
			>
				{{ $t('walksText') }}
			</v-responsive>

			<v-row>
				<v-col
					v-for="walkPost in walkPosts"
					:key="walkPost._id"
					cols="12"
					md="4"
				>
					<v-img
						:src="walkPost.image"
						class="mb-4"
						height="275"
						max-width="100%"
					></v-img>

					<h3
						class="font-weight-black mb-4 text-uppercase"
						v-text="walkPost.title"
					></h3>

					<div
						class="title font-weight-light mb-5"
						v-text="walkPost.description"
					></div>

					<v-btn
						class="ml-n4 font-weight-black"
						color="black"
						text
						@click="readMore(walkPost)"
					>
						{{ $t('continueReading') }}
					</v-btn>
				</v-col>
			</v-row>
		</v-container>

		<div class="py-12"></div>
	</section>
</template>

<script>
export default {
	name: 'AppBlog',

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			walkPosts: [],
		};
	},
	methods: {
		readMore(walkPost) {
			this.$router.push(`/walkposts/${walkPost._id}`);
		},
	},
	async mounted() {
		try {
			const res = await this.$http.get(`${this.url}/walkPosts`);

			this.walkPosts = res.data.data;
			this.walkPosts = this.walkPosts.slice(0, 3);
		} catch (error) {
			console.error(error);
		}
	},
};
</script>

<style lang="scss" scoped>
#walks {
	background: rgb(221, 221, 221);
}
</style>
